.landing-page {
    .mobile-menu-btn {
        fill: $pink-dark;
    }

    .header-wrapper {
        justify-content: flex-start;
        mix-blend-mode: darken;

        @media (max-width: $desktop) {
            justify-content: space-between;
        }

        .landing-nav {
            @media (max-width: $desktop) {
                display: none;
            }
        }
    }

    .select-below {
        @include rem(font-size, 21);
        margin: 30px auto;
    }
}