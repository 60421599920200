.hero-home-block {
    position: relative;
    text-align: center;
    border-bottom: 1px solid $gray-dark;
    padding-bottom: 1.8rem;

    .hero-image {
        width: 100%;

        img {
            height: auto !important;
            width: 100% !important;
        }
    }

    .callout-card {
        margin-top: 45px;
    }

    h1 {
        @extend .h1-serendipity;
    }

    h2 {
        @extend .h1-serendipity;
        margin-bottom: .6rem;
    }

    .serendipity-content {
        padding-top: 3rem;
        .serendipity-text {
            // margin-bottom: .6rem;
        }
    }

    p {
        @extend .font-21;
        @extend .weight-medium;
    }
    
    @media (min-width: $desktop) {
        padding-top: 160px; // whatever header height is
        padding-bottom: 1.8rem;
        text-align: left;
        position: sticky;
        top: 0;
        background-color: $white;

        .hero-image {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 31%;
            img {
                height: 100% !important;
                width: 100% !important;
                object-fit: cover;
                object-position: left top;
            }
        }

        .serendipity-content  {
            width: 62%;
            padding-top: 3rem;
            max-width: 1075px;
           .serendipity-text {
                margin-bottom: -1.35rem;
                margin-left: -.2rem;
            }
        }
    }
}


.landing-page:not(.home) {
    .hero-home-block {
        padding: 10 0 45px;
        border: none;
        @media (min-width: $desktop) {
            padding: 50px 0 80px;
            .hero-image {
                top: 60px;
            }
        }
    }
}